import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import Vue from 'vue';
import ContactSalutationTypeResourceDTO from '../../generated/types/dto/resource/ContactSalutationTypeResourceDTO';
import CreateConsultingCaseRequestModel from '../../generated/types/model/resource/CreateConsultingCaseRequestModel';

export default class CreateConsultingCaseRequestViewModel extends CreateConsultingCaseRequestModel {
  protected transformToDTO() {
    const customerSalutation = `${Vue.$t('form.address.salutation')}: ${Vue.$tEnum('form.address.salutationEnum', this.salutation!)}`;
    const customerName = `${Vue.$t('form.address.firstName')}: ${this.firstName}\n${Vue.$t('form.address.lastName')}: ${this.lastName}`;
    const customerCommunication = `${Vue.$t('form.address.email')}: ${this.email}\n${Vue.$t('form.address.phone')}: ${this.phoneNumber}`;
    this.dto.customerContactText = `${customerSalutation}\n${customerName}\n${customerCommunication}`;
  }
  protected transformFromDTO() {}

  email?: string;
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
  salutation?: number;

  constructor() {
    super();
    Vue.set(this, 'email', undefined);
    Vue.set(this, 'phoneNumber', undefined);
    Vue.set(this, 'firstName', undefined);
    Vue.set(this, 'lastName', undefined);
    Vue.set(this, 'salutation', undefined);
  }

  schema = {
    ...queryBuilder.properties({
      subject: { required: true },
      description: { required: true },
      email: { required: true },
      phoneNumber: { required: true },
      firstName: { required: true },
      lastName: { required: true },
      salutation: { required: true },
    }),
  };
}
