
















































































import Vue from 'vue';
import pRequestAlert from '@glittr/frontend-core/src/components/p-request-alert/p-request-alert.vue';
import CreateConsultingCaseRequestViewModel from '@/src/services/viewModel/resource/CreateConsultingCaseRequestViewModel';

export default Vue.extend({
  components: { pRequestAlert },
  auth: true,
  hasBackButton: true,
  data: () => ({
    isLoading: false,
    isLoadingMemberData: false,
    errorResponse: undefined as any,
    files: [] as File[],
    model: new CreateConsultingCaseRequestViewModel(),
  }),
  async mounted() {
    this.isLoadingMemberData = true;
    try {
      const membership = await this.$service.api.memberships.getMyMembership();
      this.model.email = membership?.memberContact?.address?.eMail ?? undefined;
      this.model.phoneNumber = membership?.memberContact?.address?.phoneNumber ?? undefined;
      this.model.firstName = membership?.memberContact?.address?.firstName ?? undefined;
      this.model.lastName = membership?.memberContact?.address?.lastName ?? undefined;
      this.model.salutation = membership?.memberContact?.address?.salutation ?? undefined;
    } catch (error) {
      // Don't show error screen as this is non-breaking for the user
      console.error(error);
    }
    this.isLoadingMemberData = false;
  },
  methods: {
    async sendLegalEnquiry() {
      if (!this.model.validate()) {
        return;
      }
      this.isLoading = true;
      try {
        this.model.documents = await this.$service.api.documents.UploadMultipleDocuments(this.files);
        const user = await this.$auth.getUser();
        this.model.userId = user?.id!;

        await this.$service.api.consultingCases.addConsultingCase(this.model);
        this.$router.replace('/gs/memberPortal/legal-service/legal-enquiry-sent');
      } catch (error) {
        this.errorResponse = error;
      }
      this.isLoading = false;
    },
  },
});
