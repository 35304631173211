// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2021 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import CreateConsultingCaseRequestDTO from '../../dto/resource/CreateConsultingCaseRequestDTO';

export default abstract class CreateConsultingCaseRequestModel extends BaseModel<CreateConsultingCaseRequestDTO> {
  /**
  * @type {int64}
  */
  get userId() { return this.dto.userId; }
  set userId(value) { this.dto.userId = value; }
  /**
  */
  get subject() { return this.dto.subject; }
  set subject(value) { this.dto.subject = value; }
  /**
  */
  get description() { return this.dto.description; }
  set description(value) { this.dto.description = value; }
  /**
  */
  get documents() { return this.dto.documents; }
  set documents(value) { this.dto.documents = value; }
  /**
  */
  get customerContactText() { return this.dto.customerContactText; }
  set customerContactText(value) { this.dto.customerContactText = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    value = Number.parseFloat(queries.userId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.userId = value;
    this.dto.subject = queries.subject;
    this.dto.description = queries.description;
    this.dto.customerContactText = queries.customerContactText;
  }
}
